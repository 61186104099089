Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("checkout-form-step-2")) {
        Base.components.tablist = new Vue({
            el: document.getElementById("checkout-form-step-2"),
            store,
            mixins: [Mixins.submitForm],
            data: function () {
                return {
                    hasCaptcha: false,
                    captchaExecuted: false,
                    payment_type: 'ideal',
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {
            },
            mounted: function () {

            },
            methods: {
                submit: function () {
                    const that = this;
                    this.$validator.validateAll().then(result => {
                        let analytics = that.$el.dataset.analytics;

                        if (result) {
                            if (!this.hasCaptcha || this.captchaExecuted) {
                                that.submitForm(that.$el, analytics);
                            } else {
                                this.$refs.recaptcha.execute();
                            }
                        } else {
                            // recaptcha resetten
                            this.$refs.recaptcha.reset();
                            this.captchaExecuted = false;
                        }
                    });
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                    this.submit();
                }
            },
            created: function () {

            },
            destroyed: function () {

            }
        })
    }
});
