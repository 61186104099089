Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("header")) {
        Layout.components.header = new Vue({
            el: document.getElementById("header"),
            store,
            data: function () {
                return {
                    scrolled: false,
                    headerOffset: false,
                }
            },
            computed: {
                menuExpanded: {
                    get: function () {
                        return this.$store.getters['Layout/menuExpanded']
                    },
                    set: function (newValue) {
                        this.$store.dispatch('Layout/setMenuExpanded', newValue)
                    }
                },
                searchVisible: {
                    get: function () {
                        return this.$store.getters['Layout/searchVisible']
                    },
                    set: function (newValue) {
                        this.$store.dispatch('Layout/setSearchVisible', newValue)
                    }
                },
                shoppingCartAmount: {
                    get() {
                        return this.$store.getters['Layout/shoppingCartAmount']
                    }
                }
            },
            mounted: function () {
                this.setHeaderOffset();

                this.$store.dispatch('Layout/setShoppingcartAmount');

                let that = this;

                window.addEventListener('scroll', function () {
                    that.watchScrollPosition();
                    that.watchIfScrollingUp();
                });

                window.addEventListener('resize', function () {
                    that.setHeaderOffset();
                });
            },
            methods: {
                watchScrollPosition() {
                    let that = this;
                    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

                    that.scrolled = scrollTop > document.getElementById('header').offsetHeight;
                },

                watchIfScrollingUp() {
                    this.currentScrollingPosition = window.pageYOffset || document.documentElement.scrollTop;
                    this.isScrollingDown = this.previousScrollingPosition < this.currentScrollingPosition;
                    this.previousScrollingPosition = this.currentScrollingPosition;
                },

                setHeaderOffset(){
                    var header = document.getElementById('header');
                    var headerHeight = header.offsetHeight;

                    document.querySelector('body').style.paddingTop = headerHeight + "px";
                    this.headerOffset = true;
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        })
    }
});