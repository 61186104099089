Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("usps")) {
        Base.components.usps = new Vue({
            el: document.getElementById("usps"),
            store,
            data: function () {
                return {
                    flkty: null
                }
            },
            computed: {
            },
            mounted: function () {
                this.initializeFlickity();
            },
            methods: {
                initializeFlickity() {
                    this.flkty = new Flickity(this.$refs.usps, {
                        watchCSS: true,
                        autoPlay: true,
                        pageDots: false,
                        prevNextButtons: false,
                        draggable: true
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        })
    }
});