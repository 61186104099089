Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("checkout-form-step-3")) {
        Base.components.tablist = new Vue({
            el: document.getElementById("checkout-form-step-3"),
            store,
            mixins: [Mixins.submitForm],
            data: function () {
                return {

                }
            },
            components: {

            },
            computed: {
                paymentStatus: {
                    get() {
                        return this.$store.getters['Shop/paymentStatus']
                    }
                }
            },
            mounted: function () {
                this.$store.dispatch('Shop/setPaymentStatus');
            },
            methods: {

            },
            created: function () {

            },
            destroyed: function () {

            }
        })
    }
});