Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("productSlider")) {
        Base.components.productSlider = new Vue({
            el: document.getElementById("productSlider"),
            store,
            data: function () {
                return {
                }
            },
            computed: {
                images: function () {
                    return this.$refs.productDetailSlider.querySelectorAll('.product--detail__slide');
                }
            },
            mounted: function () {
                this.initProductslider();
                this.initProductsliderNav();
            },
            mixins: [Mixins.productLightboxes],
            methods: {
                initProductslider() {
                    let flkty = new Flickity(this.$refs.productDetailSlider, {
                        autoPlay: false,
                        wrapAround: true,
                        pageDots: false,
                        draggable: ('ontouchstart' in window)
                    });

                    this.createLightboxes(this.images);
                },

                initProductsliderNav() {
                    let flkty = new Flickity(this.$refs.productDetailSliderNav, {
                        pageDots: false,
                        prevNextButtons: false,
                        contain: true,
                        wrapAround: this.images.length > 3,
                        cellAlign: 'left',
                        asNavFor: this.$refs.productDetailSlider,
                        draggable: ('ontouchstart' in window)
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        })
    }
});