Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-overview")) {
        Shop.components.products = new Vue({
            el: document.getElementById("product-overview"),
            store,
            data: function () {
                return {
                    filterScrolled: false,
                }
            },
            computed: {
                filterExpanded: {
                    get: function () {
                        return this.$store.getters['Shop/filterExpanded']
                    },
                    set: function (newValue) {
                        this.$store.dispatch('Shop/setFilterExpanded', newValue)
                    }
                },
            },
            mounted: function () {
                this.asideMenu();
                this.priceSlider();
                this.sortProducts();
                this.setProductHeight();

                let that = this;

                document.querySelector('.shop-aside').addEventListener('scroll', function () {
                    that.showFilterSubmit();
                });
            },

            watch: {

            },

            methods: {
                asideMenu: function() {
                    let items = document.querySelectorAll('.page-submenu__item--active');
                    if (items) {
                        for (let i = 0; i < items.length; i++) {
                            let item = items[i];
                            let link = item.querySelector('.page-submenu__link');

                            link.addEventListener('click', function (event) {
                                event.preventDefault();
                                item.classList.toggle('page-submenu__item--closed');
                                return false;
                            });
                        }
                    }
                },

                setSort: function(e) {

                },
                sortProducts: function() {
                    let sortSelectr = new Selectr('#sort-products', {
                        searchable: false,
                        width: 300
                    });
                    sortSelectr.on('selectr.change', function(option) {
                        let filter = document.getElementById('filter').querySelector('form');
                        filter.querySelector('[name="sort"]').value = option.value;
                        filter.submit();
                    });
                },

                submitForm: function(e) {
                    e.target.form.submit();
                },

                toggleParent: function (i, e) {
                    document.querySelector('.shop-filters__list--' + i).classList.toggle('shop-filters__list--active');
                },

                setProductHeight: function(){
                    var bodyWidth = document.querySelector('body').offsetWidth;
                    var products = document.getElementsByClassName('product');
                    var productSpecsHeight = 0;
                    var productTitleHeight = 0;

                    for (var i = 0; i < products.length; i++) {
                        var thisProductSpecsHeight = (products[i].querySelector('.product__specs').offsetHeight);
                        var thisProductTitleHeight = (products[i].querySelector('.product__title').offsetHeight);



                        if (bodyWidth > 768) {

                            if(thisProductTitleHeight > productTitleHeight) {
                                productTitleHeight = thisProductTitleHeight;
                                var productTitle = document.getElementsByClassName('product__title');
                                for (var x = 0; x < productTitle.length; x++) {
                                    productTitle[x].style.minHeight = productTitleHeight + "px";
                                }
                            }

                            if(thisProductSpecsHeight > productSpecsHeight) {
                                productSpecsHeight = thisProductSpecsHeight;

                                var productSpecs = document.getElementsByClassName('product__specs');
                                for (var x = 0; x < productSpecs.length; x++) {
                                    productSpecs[x].style.minHeight = productSpecsHeight + "px";
                                }
                            }

                        } else if (bodyWidth <= 768) {
                            var productTitle = document.getElementsByClassName('product__title');
                            for (var x = 0; x < productTitle.length; x++) {
                                productTitle[x].style.minHeight = "0px";
                            }

                            var productSpecs = document.getElementsByClassName('product__specs');
                            for (var x = 0; x < productSpecs.length; x++) {
                                productSpecs[x].style.minHeight = "0px";
                            }
                        }
                    }
                },

                priceSlider: function() {
                    var priceSlider = document.getElementById('price__slider');
                    var input0 = document.getElementById('price--min');
                    var input1 = document.getElementById('price--max');

                    if (! priceSlider || ! input0 || ! input1) {
                        return;
                    }

                    var inputs = [input0, input1];

                    var rangeMin = parseFloat(input0.placeholder);
                    var rangeMax = parseFloat(input1.placeholder);

                    rangeMin = parseInt(rangeMin.toString().replace('€', '').replace('.', ','));
                    rangeMax = parseInt(rangeMax.toString().replace('€', '').replace('.', ','));

                    var startMin = input0.getAttribute('value');
                    startMin = parseInt(startMin.replace(/[^0-9]+/g, ''));
                    if (! startMin) {
                        startMin = rangeMin;
                    }

                    var startMax = input1.getAttribute('value');
                    startMax = parseInt(startMax.replace(/[^0-9]+/g, ''));
                    if (! startMax) {
                        startMax = rangeMax;
                    }

                    noUiSlider.create(priceSlider, {
                        start: [startMin, startMax],
                        step: 10,
                        connect: true,
                        range: {
                            'min': rangeMin,
                            'max': rangeMax
                        },
                        format: wNumb({
                            decimals: 3,
                            thousand: '.',
                            prefix: ' € '
                        })
                    });

                    priceSlider.noUiSlider.on('update', function (values, handle) {
                        inputs[handle].value = values[handle];
                    });
                },
                showFilterSubmit: function () {
                    const scrollTop = document.querySelector('.shop-aside').scrollTop + document.getElementById('header').clientHeight + (window.innerHeight / 2);

                    this.filterScrolled = scrollTop > document.getElementById('filter').offsetTop;
                }
            },

            created: function () {
            },
            destroyed: function () {
            }
        })
    }
});