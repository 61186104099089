Layout.store.actions = {
    setMenuExpanded({commit}, value) {
        commit("setMenuExpanded", value)
    },
    setSearchVisible({commit}, value) {
        commit("setSearchVisible", value)
    },
    setShoppingcartAmount({commit}) {
        Layout.api.getShoppingcartCount().then(function(response) {
            store.commit("Layout/setShoppingcartAmount", response)
        });
    }
};