Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("shoppingcart")) {
        Shop.components.products = new Vue({
            el: document.getElementById("shoppingcart"),
            store,
            computed: {
            },
            mounted: function () {
            },
            watch: {
            },

            methods: {
                updateProductInCart(i, e) {
                    document.querySelector('.checkout-product__form--update-' + i).submit();
                }
            },

            created: function () {
            },
            destroyed: function () {
            }
        })
    }
});