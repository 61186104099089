Mixins.productLightboxes = {
    data: function () {
        return {
            openImage: -1
        }
    },
    methods: {
        createLightboxes: function (images) {
            if (document.body.classList.contains('backoffice') || ! images.length) {
                return false;
            }

            for (let i = 0; i < images.length; i++) {
                let image = images[i];
                image.onclick = () => {
                    let src = image.src;
                    if (image.dataset.large) {
                        src = image.dataset.large;
                    }

                    if (document.getElementsByClassName('backdrop').length == 0) {
                        let backdrop = document.createElement('div');
                        backdrop.classList.add('backdrop');
                        backdrop.classList.add('backdrop--visible');
                        document.body.appendChild(backdrop);
                    }

                    basicLightbox.create('<img width="auto" height="100%" src="' + src + '" />', {
                        onShow: (instance) => {
                            this.openImage = i;
                        },
                        onClose: (instance) => {
                            if (this.openImage === i) {
                                this.openImage = -1;
                            }

                            this.closeBackdrop();
                        }
                    }).show();
                }
            }

            document.addEventListener("keyup", this.handleKeyUp);

        },
        handleKeyUp: function(e) {

            if(this.openImage >= 0) {
                let i = this.openImage;
                let images = this.images;
                let iterator = -1;
                let keyCode = e.keyCode;

                // eerst kijken of het esc is
                if(keyCode == 27) {
                    // escape / sluiten
                    this.closeLightbox();
                    this.closeBackdrop();
                }

                // onderstaande deel alleen bij meerdere afbeeldingen uitvoeren
                if (typeof images == "undefined") {
                    return false;
                }

                if(keyCode == 39) {
                    // right
                    iterator = i + 1;

                    if (iterator == images.length) {
                        // terug naar eerste
                        iterator = 0;
                    }
                } else if(keyCode == 37) {
                    // left
                    iterator = i - 1;

                    if (iterator == -1) {
                        // naar laatste
                        iterator = images.length - 1;
                    }
                }

                if (images[iterator]) {
                    this.closeLightbox();

                    images[iterator].click();
                }
            }

            return true;
        },

        closeLightbox: function () {
            let boxes = document.querySelectorAll('.basicLightbox');
            boxes.forEach(function (box) {
                box.classList.remove('basicLightbox--visible');

                setTimeout(function () {
                    box.remove();
                }, 410);
            });
        },

        closeBackdrop: function () {
            let backdrop = document.querySelector('.backdrop');
            backdrop.classList.remove('backdrop--visible');

            setTimeout(function () {
                backdrop.remove();
            }, 410);
        }
    }
};