Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("add-to-cart")) {
        Shop.components.products = new Vue({
            el: document.getElementById("add-to-cart"),
            store,
            computed: {
                messageClassObject: function () {
                    return {
                        'add-to-cart-message--active': this.message.show,
                        'add-to-cart-message--success': (this.message.type === 'success'),
                        'add-to-cart-message--danger': (this.message.type === 'danger'),
                    };
                },
            },
            data: function () {
                return {
                    message: {
                        show: false,
                        type: 'success',
                        text: null,
                        timeout: null
                    }
                }
            },
            mounted: function () {
            },
            watch: {

            },
            methods: {
                submit(e) {
                    return axios({
                        method: 'post',
                        url: this.$el.action,
                        data: new FormData(this.$el)
                    }).then((response) => {
                        if (response.data.hasOwnProperty('target')) {
                            return window.location.href = response.data.target;
                        } else if (response.data.hasOwnProperty('message')) {
                            this.setMessage(response.data.message);
                        }

                        this.$store.dispatch('Layout/setShoppingcartAmount');

                        return true;
                    }).catch((error) => {
                        this.setMessage(error.response.data.message, 'danger');
                        return false;
                    });
                },
                setMessage(text, type) {
                    if (this.message.timeout !== null) {
                        clearTimeout(this.message.timeout);
                    }

                    this.message.type = 'success';
                    if (type !== undefined) {
                        this.message.type = type;
                    }

                    this.message.text = text;
                    this.message.show = true;

                    this.message.timeout = setTimeout(() => {
                        this.message.show = false;
                        setTimeout(() => {
                            this.message.text = null;
                        }, 500);
                    }, 5000);
                },
                closeMessage() {
                    if (this.message.timeout !== null) {
                        clearTimeout(this.message.timeout);
                    }

                    this.message.show = false;

                    setTimeout(() => {
                        this.message.show = null;
                    }, 500);
                },
                showVariant(e) {
                    if (e.target.options.selectedIndex > -1) {
                        return Barba.Pjax.goTo(e.target.options[e.target.options.selectedIndex].value)
                    }

                    return false;
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        })
    }
});
