Layout.store.getters = {
    menuExpanded(state) {
        return state.menuExpanded;
    },
    searchVisible(state) {
        return state.searchVisible;
    },
    shoppingCartAmount(state) {
        return state.shoppingCartAmount;
    }
};