Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("tablist")) {
        Base.components.tablist = new Vue({
            el: document.getElementById("tablist"),
            store,
            data: function () {
                return {
                    activeTab: 1
                }
            },
            computed: {
            },
            mounted: function () {

            },
            methods: {

            },
            created: function () {

            },
            destroyed: function () {

            }
        })
    }
});