Layout.store.mutations = {
    setMenuExpanded(state, value) {
        state.menuExpanded = value;
    },
    setSearchVisible(state, value) {
        state.searchVisible = value;
    },
    setShoppingcartAmount(state, value) {
        state.shoppingCartAmount = value;
    }
};